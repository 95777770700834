import Vue from 'vue'
import Router from 'vue-router'

// import Login from '../components/login/login.vue'
// import Home from '../components/home.vue'
// import Welcome from '../components/welcome.vue'
const Login = () => import(/* webpackChunkName: "group-login" */ '../components/login/login.vue')
const Home = () => import(/* webpackChunkName: "group-login" */ '../components/home.vue')
const Welcome = () => import(/* webpackChunkName: "group-login" */ '../components/welcome.vue')

// import Courses from '../components/courses/courses.vue'
// import userCourses from '../components/userCourses/userCourses.vue'
// import Lessons from '../components/lessons/lessons.vue'
// import CoursePage from '../components/coursePage/coursePage.vue'
// import CoursePage from '../components/coursePage/coursePage2.vue'
const Courses = () => import(/* webpackChunkName: "group-course" */ '../components/courses/courses.vue')
const userCourses = () => import(/* webpackChunkName: "group-course" */ '../components/userCourses/userCourses.vue')
const Lessons = () => import(/* webpackChunkName: "group-course" */ '../components/lessons/lessons.vue')
const CoursePage = () => import(/* webpackChunkName: "group-course" */ '../components/coursePage/coursePage2.vue')

// import UserCenter from '../components/userCenter/userCenter.vue'
const UserCenter = () => import(/* webpackChunkName: "group-UserCenter" */ '../components/userCenter/userCenter.vue')

// import Ideas from '../components/ideas/ideas.vue'
const Ideas = () => import(/* webpackChunkName: "group-Ideas" */ '../components/ideas/ideas.vue')
const Activity = () => import('../components/activity/activity.vue')

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '', redirect: '/login' },
    { path: '/login', component: Login },
    // { path: '/page', component: CoursePage },
    {
      path: '/home',
      component: Home,
      redirect: '/welcome',
      meta: { keepAlive: true },
      children: [
        { name: 'welcome', path: '/welcome', component: Welcome },
        { name: 'userCourses', path: '/userCourses', component: userCourses, meta: { keepAlive: true } },
        { name: 'userCenter', path: '/userCenter', component: UserCenter, meta: { keepAlive: true } },
        { name: 'lessons', path: '/lessons', component: Lessons, meta: { keepAlive: true } },
        { name: 'page', path: '/page', component: CoursePage, meta: { keepAlive: true } },
        { name: 'courses', path: '/courses', component: Courses, meta: { keepAlive: true } },
        { name: 'ideas', path: '/ideas', component: Ideas, meta: { keepAlive: true } },
        { name: 'activity', path: '/activity', component: Activity, meta: { keepAlive: true } }
      ]
    }
  ],
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    console.log('from: ', from)
    console.log('to: ', to)
    console.log('savedPosition: ', savedPosition)
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.documentElement.scrollTop || document.body.scrollTop
      }
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  }
})

// fix error:Uncaught (in promise) Error: Avoided redundant navigation to current location
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 加入路径导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 从何处跳转而来
  // next 下一步的动作
  if (to.path === '/login') return next()
  const token = window.sessionStorage.getItem('token')
  // console.log('导航守卫', token)
  if (!token) return next('/login')
  next()
})
export default router
