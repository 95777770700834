import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueParticles from 'vue-particles'
import * as echarts from 'echarts'
import VueLazyload from 'vue-lazyload'
import './plugins/element.js'

// 导入全局样式表
import './assets/css/global.css'

import axios from 'axios'

// 进度条NProgress导入
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// import { config } from 'vue/types/umd'
axios.defaults.baseURL = 'https://pdsedu.starmaker-china.com/api/'
// axios.defaults.baseURL = 'http://127.0.0.1:8001/api/'

// 进度条 NProgress
// 在request中展示NProgress进度条 NProgress.start()
axios.interceptors.request.use(config => {
  NProgress.start()
  config.headers.token = window.sessionStorage.getItem('token')
  return config
})
// 在response中关闭NProgress进度条 NProgress.done()
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})
Vue.use(VueParticles)
Vue.prototype.$echarts = echarts
// Vue.use(VueLazyload)
// or with options
const loadimage = require('./assets/images/loading.gif')
const errorimage = require('./assets/images/error.png')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 3, // 尝试加载的次数
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove'] // 你想让vue监听的事件
})
Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
